import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "userAccount _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }), _vm._l(_vm.allLinks, function (link) {
    return _c('div', {
      staticClass: "wiki"
    }, [_c('h4', [_vm._v(_vm._s(link.name))]), _c('span', {
      staticClass: "padding"
    }, [_c('a', {
      staticClass: "link bold",
      attrs: {
        "href": link.url,
        "target": "_blank"
      }
    }, [_vm._v("Download")])]), _c('span', {
      staticClass: "padding"
    }, [_c('span', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          link.show = !link.show;
        }
      }
    }, [_vm._v("Anzeigen")])]), link.show ? _c('div', {
      staticClass: "embeded"
    }, [_c('embed', {
      attrs: {
        "src": link.url,
        "width": "100%",
        "height": "100%",
        "type": "application/pdf"
      }
    })]) : _vm._e()]);
  })], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Wiki")])]);
}];
export { render, staticRenderFns };